* {
    font-family: 'arial', 'microsoft yahei';
}

.col {
    composes: clearfix from global;
    position: relative;
    width: 1000px;
    margin: 0 auto;
    padding-top: 20px;
}

.ifengLogo,
.topic {
    float: left;
    padding-right: 20px;
}

.ifengLogo span {
    display: inline-block;
    width: 139px;
    height: 52px;
    background: url(./logo.jpg) no-repeat;
}

.topic span {
    display: inline-block;
    width: 312px;
    height: 55px;
    background: url(./pic01.jpg) no-repeat;
}

.tip404 {
    position: relative;
    padding: 45px 0 0 0;
    height: 320px;
    float: left;
    width: 450px;
    & h1 {
        font: normal 26px/26px Microsoft Yahei;
        color: #4c4c4c;
        padding-bottom: 8px;
    }
    & ul li {
        font-size: 14px;
        line-height: 1.6em;
        color: #aaa;
        background: url(./icon.gif) no-repeat 0 9px;
        padding-left: 15px;
    }
    & h4 {
        padding: 11px 0 30px;
        & span {
            display: inline-block;
            width: 162px;
            height: 34px;
            background: url(./gaijin.png) no-repeat;
        }
    }
}

.leftLink {
    width: 445px;
    float: left;
    height: 356px;
    margin-right: 105px;
    overflow: hidden;
    margin-top: 15px;
    background: url('./404.gif') no-repeat;
}

.img404 span {
    display: inline-block;
    width: 250px;
    height: 106px;
    background: url('./404.png') no-repeat;
}

.tip span {
    font: normal 16px/24px Arial, Helvetica, sans-serif;
    color: #000;
}

.dataBox {
    width: 998px;
    height: 350px;
    background: #f7f7f7;
    border: 1px solid #e8e8e8;
    margin: 0 auto;
    position: relative;
    margin-bottom: 20px;
}

.boxL {
    float: left;
    width: 400px;
    padding: 18px 40px 0 40px;
}

.boxR {
    float: right;
    width: 400px;
    padding: 18px 40px 0 40px;
}

.footer {
    width: 1000px;
    margin: 0 auto;
}

.foot_link {
    position: relative;
    height: 28px;
    padding-top: 15px;
    overflow: hidden;
    color: #000;
    line-height: 28px;
    font-size: 12px;
    & a {
        color: #000;
    }
}

.footL {
    float: left;
}

.footR {
    width: 200px;
    float: right;
    & ul {
        overflow: hidden;
        & li {
            height: 28px;
            line-height: 28px;
            overflow: hidden;
            float: left;
        }
    }
}

.bg001 {
    background: url(./bg001.gif) no-repeat left 9px;
    padding-left: 19px;
}

.bg002 {
    background: url(./bg002.gif) no-repeat left 9px;
    padding-left: 17px;
    margin-left: 18px;
}

.list {
    text-align: center;
}

.link {
    color: #000 !important;
    font-size: 12px;
    text-decoration: none;
    padding: 0 7px;
    border-right: 1px solid #000;
}

.link:hover {
    color: #f54343 !important;
    text-decoration: underline;
}

.link:first-child {
    padding-left: 0;
}

.link:last-child {
    border: 0;
}

.copyright {
    font-family: Arial, '宋体';
    color: #666;
    height: 28px;
    text-align: center;
    overflow: hidden;
    line-height: 28px;
    & span {
        padding-right: 12px;
        font-size: 12px;
    }
}
