.pao_mid {
    width: 445px;
    height: 266px;
    border: 1px solid #cfcfcf;
    float: left;
    margin: 47px 102px 0 0;
    & [ifeng_ui_pc_sliders-dots] {
        left: 145px;
    }
    & [ifeng_ui_pc_sliders-title] {
        height: 32px;
        font: 14px '微软雅黑';
        line-height: 32px;
        color: #fff;
        &:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
}
.box {
    width: 418px;
    height: 235px;
    overflow: hidden;
    position: relative;
    margin: 15px auto 0;
    background: #ccc;
}

.dot {
    float: left;
    margin-right: 3px;
    display: inline;
    cursor: pointer;
    background: #fff;
    width: 7px;
    height: 7px;
    text-indent: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 6px;
}

.current {
    composes: dot;
    background: #f34443;
}
