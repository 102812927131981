.pplive {
    width: 445px;
    float: left;
    height: 356px;
    margin-right: 105px;
    overflow: hidden;
    margin-top: 15px;
}

.list {
    width: 482px;
    & li {
        width: 130px;
        float: left;
        margin-right: 30px;
        margin-bottom: 27px;
        overflow: hidden;
        & a {
            display: block;
            width: 130px;
            height: 100px;
            position: relative;
            & span {
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                width: 60px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                color: #ffffff;
                font-size: 14px;
                background: #f54343;
            }
        }
        & h3 {
            font-size: 14px;
            color: #333333;
            line-height: 16px;
            margin: 14px 0 8px;
            font-weight: normal;
            height: 16px;
            overflow: hidden;
        }
        & p {
            font-size: 12px;
            color: #999999;
            line-height: 14px;
            padding-left: 16px;
            background: url(./pao_pic.png) no-repeat left center;
        }
    }
}
