.title {
    font: bold 14px Microsoft Yahei;
    color: #2b2b2b;
    border-bottom: 1px solid #e5e5e5;
    height: 24px;
    margin-bottom: 10px;
    & span {
        float: left;
        border-bottom: 2px solid #bd0014;
        height: 22px;
    }
}

.list li {
    height: 35px;
    line-height: 35px;
    background: url(./s_11.gif) no-repeat 5px 10px;
}

.red {
    font-size: 18px;
    font-weight: bold;
    float: left;
    width: 16px;
    height: 23px;
    color: #ba2636;
    text-align: left;
    margin-right: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: oblique;
}

.black {
    font-size: 18px;
    font-weight: bold;
    float: left;
    width: 16px;
    height: 23px;
    color: #666666;
    text-align: left;
    margin-right: 10px;
    font-family: Arial, Helvetica, sans-serif;
}
